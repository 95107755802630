
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Header from './components/Header/Header';
import AboutUs from './pages/AboutUs/AboutUs';
import Services from "./pages/Services/Services"
import Social from './components/Social/Social';

function App() {
  return (
    <BrowserRouter>
      <div className='full'>
        <div className='header'>
          <Header />
        </div>
        <div className='mainBG'>
          <div className='main_container'>

            <div className="main_content">

              <Routes>
                <Route path="/" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
              </Routes>
            </div>
            <Social />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
