import "./Social.css"

function Social() {
    return ( 
        <div className="social_main_block">
        <div className="address_block">
            <h3>
                Наша адреса
            </h3>
            <div>
                <p>вулиця Хрещатик, 214, м.Черкаси, 18000</p>
                <iframe title="Где мы?" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d558.9324991921646!2d32.067232832206095!3d49.4421238063482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14b7c7eee2311%3A0xea249d475a8ba0ca!2sPp%20%22Azhio%22%2C%20Otsinka%20Mayna!5e0!3m2!1sen!2sca!4v1667940298779!5m2!1sen!2sca" style={{width:"100%", height:"450" ,style:"border:0;" ,allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}} ></iframe>
            </div>
        </div>
        <div className="phone_block">
            <h3>Подзвоніть нам</h3>

            <div className="table_block">
                <table className="phone_table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Тел:</strong>
                            </td>
                            <td>
                               <a href="tel:(0472) 54-02-92">(0472) 54-02-92</a> 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Тел. моб:</strong>
                            </td>
                            <td>
                                <a href="tel:(067) 217-20-51">(067) 217-20-51</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Тел. моб:</strong>
                            </td>
                            <td>
                                <a href="tel:(067) 688-75-55">(067) 688-75-55</a> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="mail_block">
            <h3>Електронна адреса</h3>
           <span> Ви завжди можете написати нам на нашу пошту: <a href="mailto:agio.otsinka@ukr.net">agio.otsinka@ukr.net</a></span>
            

        </div>
    </div>
    );
}

export default Social;