import "./AboutUs.css"

function About_us() {

    return (
        <div className="main_text">
            <div className="main_title" >
                <h1>ЕКСПЕРТНА ОЦІНКА МАЙНА <br />ОЦІНКА МАЙНА ТА МАЙНОВИХ ПРАВ</h1>
            </div>
            <div className="text">
                <span >
                    <b>ЧЕРКАСЬКА ОЦІНОЧНА КОМПАНІЯ "Ажіо" </b>
                    – консалтингова компанія, що створена групою досвідчених eкспертів-оцінювачів та аналітиків з метою надання професійних послуг з експертної оцінки майна та майнових прав на території України.
                    ПП "Ажіо." здійснюють свою діяльність на підставі Сертифікатів суб’єктів оціночної діяльності виданих Фондом державного майна України.  
                </span>
            </div>
        </div>

    );
}

export default About_us;