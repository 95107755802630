function Sertificates() {
    
    return (
        <div className="maintext">
            <div className="text">
                <span>
                    Пріоритетним напрямком діяльності є надання оціночних послуг, а саме
                    <strong> незалежна експертна оцінка вартості майна та майнових прав:</strong>
                </span>
            </div>
            <div className="mainlist">
                <ol>
                    <li>Оцінка для цілей оподаткування (оцінка для нотаріусів)</li>
                    <li>Оцінка нерухомості</li>
                    <li>Оцінка транспортних засобів</li>
                    <li>Оцінка машин і обладнання</li>
                    <li>Оцінка цілісних майнових комплексів, паїв, цінних паперів, майнових прав та нематеріальних активі</li>
                    <li> Оцінка прав на об’єкти інтелектуальної власності</li>
                </ol>
            </div>
        </div>

    );
}

export default Sertificates;