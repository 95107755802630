import { useState } from "react";
import { NavLink } from "react-router-dom";
import image2 from "./302172543_772122860868200_453631090233615219_n.jpg"
import "./header.css"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import React from "react";


function Header() {
    const location = useLocation()

    console.log(location);
    const [isHeaderOpen, setIsHeaderOpen] = useState(false)

    useEffect(() => {
        setIsHeaderOpen(false)
    }, [location.key])



    const Style = ({ isActive }) => isActive ? { color: "grey", textDecoration: "none" } : {}
    return (

        <div className="header_container">
            <div className="main_header_container">
                <div className="logo_main_header_block">
                    <NavLink className={"main_logo"} to="/">
                        <img className="logo_img" src={image2} alt="Our logo"></img>
                        <p className="Mainname">ПП"Ажіо"</p>
                    </NavLink>
                </div>

                <div className="header_links_block desktop">
                    <div className="links">
                        <NavLink className={(data) => `${location.pathname === '/' ? 'active_link' : ''} header_link`} to="/">Про Нас</NavLink>
                        <NavLink className={(data) => `${data.isActive ? 'active_link' : ''} header_link`} to="/services">Послуги</NavLink>
                    </div>

                </div>
                <div className="social_block desktop">
                    <a href="https://www.facebook.com/agio.otsinka/" bis_skin_checked="1" target="_blank" rel="noreferrer" className="icon_facebook">
                        Ми в Facebook
                    </a>
                </div>

                <div className="hamburger mobile">
                    <span className="hamburger" onClick={() => setIsHeaderOpen(!isHeaderOpen)}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </span>
                    {isHeaderOpen && (
                        <div className="header_nav_menu">
                            <NavLink className={(data) => `${location.pathname === '/' ? 'active_link' : ''} header_link`} to="/">Про Нас</NavLink>
                            <NavLink className={(data) => `${data.isActive ? 'active_link' : ''} header_link`} to="/services">Послуги</NavLink>
                            <a href="https://www.facebook.com/agio.otsinka/" bis_skin_checked="1" target="_blank" rel="noreferrer" className="icon_facebook">
                                Мы в Facebook
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
}

export default React.memo(Header);